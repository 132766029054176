import { Typography } from '@mui/material';
import ImagePageNotFound from '../../../assets/images/undraw_page_not_found.svg';
import PageContent from '../../4_templates/PageContent';

function Image404() {
  return (
    <div style={{ display: 'flex' }}>
      <img
        src={ImagePageNotFound}
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          width: '100%',
          // minWidth: '300px',
          backgroundColor: 'white',
          borderRadius: '4px',
        }}
        alt="404 Not found"
      />
    </div>);
}

function NotFound({ withContainer }: { withContainer?: boolean }) {
  return (
    <PageContent header={withContainer?"Page non trouvée":undefined}>
      {!withContainer && <Typography variant='h5' mt={4} textAlign='center'>Page non trouvée</Typography>}
      <Image404 />
    </PageContent>);
}

export default NotFound;

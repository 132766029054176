import React from 'react';
import { Container, Paper, Stack, Breakpoint } from '@mui/material';
import PageHeader from '../3_organisms/PageHeader';

export interface PageContentProperties {
  header?: string | React.ReactNode;
  children: React.ReactNode;
  maxWidth?: Breakpoint | false;
}

function PageContent({ header, children, maxWidth }: PageContentProperties) {
  return (
    <Stack spacing={0}>
      <>
        {header && typeof header === 'string' && <PageHeader>{header}</PageHeader>}
        {header && typeof header !== 'string' && { header }}
        <Stack component={Paper} sx={{ minHeight: '100vh' }} borderRadius={0} elevation={0}>
          <Container disableGutters maxWidth={maxWidth}
                     sx={{
                       margin: 0,
                       display: 'flex',
                       flexDirection: 'column',
                       // alignItems: 'center',
                       // justifyContent: 'center',
                       //  minHeight: '100vh',
                     }}>
            {children}
          </Container>
        </Stack>
      </>
    </Stack>
  );
}

export default PageContent;
import {Alert, Stack} from '@mui/material';
import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

function ApiError({error}: { error: FetchBaseQueryError | SerializedError | undefined }) {
    const defaultError = 'Une erreur est survenue lors de la dernière action.';
    if (!error) return null;

    console.log(error);

    if ('status' in error) {
        const {status} = error;
        if (status === 404)
            return <Alert severity='error'>Informations demandées non disponible sur le serveur.</Alert>;
        if (status === 401)
            return <Alert severity='error'>Vous n'êtes pas autorisé à accéder à cette ressource.</Alert>;
        if (status === 403)
            return <Alert severity='error'>Vous n'êtes pas autorisé à effectuer cette action.</Alert>;
        if (status === 500)
            return <Alert severity='error'>Une erreur inconnue est survenue sur le serveur</Alert>;
    }
    

    if ('data' in error) {
        const data: {
            message?: string,
            title?: string,
            type?: string,
            errors?: Record<string, string[]>
        } = error.data as any;
        
        // Data peut être là mais null
        if (!data) return <Alert severity='error'>Une erreur inconnue est survenue sur le serveur</Alert>

        if (data.type === 'validation' && 'errors' in data) {
            const errors = data.errors as Record<string, string[]>;
            return <Stack spacing={1}>
                {Object.keys(errors).map(code => <Alert key={code} severity='error'>{errors[code].join(", ")}</Alert> )}

            </Stack>
        }

        if (('message' in data || 'title' in data)) {
            const {message, title} = error.data as { message: string; title: string };
            return <Alert severity='error'>{message || title || defaultError}</Alert>;
        }
        return <Alert severity='error'>Une erreur inconnue est survenue sur le serveur</Alert>

    }
    return <Alert severity='error'>{defaultError}</Alert>;
}

export default ApiError;

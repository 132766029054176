import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../components/1_atoms/Images/NotFound';
import GenericLoader from '../components/2_molecules/Loaders/GenericLoader';
import Login from '../components/5_pages/Account/Login';
import RootIndex from '../components/5_pages/Root/RootIndex';
import RequireAuth from './RequireAuth';
import SPGQSpecialRoutes from './SPGQSpecialRoutes';

const LazyAdminRoot = React.lazy(() => import('./AdminRoutes'));
const LazyCarteMembreRoot = React.lazy(() => import('./CarteMembreRoutes'));
const LazySysAdminRoot = React.lazy(() => import('./SystemAdminRoutes'));
const LazyFormulaireRoutes = React.lazy(() => import('./FormulaireRoutes'));

function AdminRoutes() {
  return (
    <React.Suspense fallback={<GenericLoader />}>
      <RequireAuth>
        <LazyAdminRoot />
      </RequireAuth>
    </React.Suspense>
  );
}

function FormulaireRoutes() {
  return (
    <React.Suspense fallback={<GenericLoader />}>
      <LazyFormulaireRoutes />
    </React.Suspense>
  );
}

function SystemAdminRoutes() {
  return (
    <React.Suspense fallback={<GenericLoader />}>
      <RequireAuth>
        <LazySysAdminRoot />
      </RequireAuth>
    </React.Suspense>
  );
}


function CarteMembreRoutes() {
  return (
    <React.Suspense fallback={<GenericLoader />}>
      <LazyCarteMembreRoot />
    </React.Suspense>
  );
}

function RootRoutes() {
  return (
    <Routes>
      {/* Hack temporaire pour le SPGQ pour que les routes existantes fonctionnent */}
      <Route path="consentement/:idEmploye" element={<SPGQSpecialRoutes formulaire='consentement'  />} />
      <Route path="signature/:idEmploye" element={<SPGQSpecialRoutes formulaire='signature' />} />
      <Route path="signature/avec-consentement/:idEmploye" element={<SPGQSpecialRoutes formulaire='signature-avec-consentement' />} />
      {/* Fin du hack */}
      
      <Route
        index
        element={<RootIndex />}
      />
      <Route
        path="admin/*"
        element={<AdminRoutes />}
      />
      <Route
        path="sysadmin/*"
        element={<SystemAdminRoutes />}
      />
      <Route
        path="cartemembre/*"
        element={<CarteMembreRoutes />}
      />
      <Route
        path="formulaire/*"
        element={<FormulaireRoutes />}
      />

      <Route
        path="login"
        element={<Login />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}

export default RootRoutes;

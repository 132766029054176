/* eslint-disable import/no-cycle */

// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployeSignatureGetData } from '../services/Schemas/EmployeSignatureGet';
import signatureApi from '../services/signatureApi';
import { RootState } from './store';
import { FormulairePourSignatureGetData } from '../services/Schemas/Formulaires';
import formulaireApi from '../services/formulaireApi';

const sliceName = 'signature';

export type DemissionInfo = { image: string, date: string, syndicat: string };

export interface SignatureSlice {
  currentEtapeIndex: number;
  employeInfo?: EmployeSignatureGetData;
  formulaire?: FormulairePourSignatureGetData;
  infoMAJ: boolean;
  adhesion?: string;
  idUnique?: string;
  signatureCarte?: string;
  signatureDemission: Record<string, DemissionInfo>;
}

const initialState: SignatureSlice = { currentEtapeIndex: 0, infoMAJ: false, signatureDemission: {} };

export const signatureSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    goToNextEtape: (state) => {
      state.currentEtapeIndex += 1;
    },
    goToPreviousEtape: (state) => {
      state.currentEtapeIndex -= 1;
      if (state.currentEtapeIndex < 0) {
        state.currentEtapeIndex = 0;
      }

    },


    updateInfosPerso: (state, action: PayloadAction<EmployeSignatureGetData>) => {
      state.employeInfo = {
        ...action.payload,
        config: state.employeInfo?.config,
        consentements: state.employeInfo?.consentements || [],
        employeurNom: state.employeInfo?.employeurNom || '',
        idUnique: state.employeInfo?.idUnique || '',
        syndicatsMaraudeur: state.employeInfo?.syndicatsMaraudeur || [],
      };
      state.infoMAJ = true;
    },
    setSignatureCarte: (state, action: PayloadAction<string | undefined>) => {
      state.signatureCarte = action.payload;
    },
    setSignatureDemission: (state, action: PayloadAction<DemissionInfo>) => {
      state.signatureDemission[action.payload.syndicat] = action.payload;
    },
    removeSignatureDemission: (state, action: PayloadAction<string>) => {
      delete state.signatureDemission[action.payload];
    },

    setAdhesion: (state, action: PayloadAction<string>) => {
      state.adhesion = action.payload;
    },
    setConsentement: (state, action: PayloadAction<{ id: number, value: boolean }>) => {
      if (!state.employeInfo) return;
      const { id, value } = action.payload;
      const existing = state.employeInfo.consentements.find(x => x.objetDeConsentementId === id);
      if (!existing) {
        state.employeInfo.consentements.push({ objetDeConsentementId: id, consent: value });
      } else {
        existing.consent = value;
      }

    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(formulaireApi.endpoints.getFormulaireByRoute.matchFulfilled, (state, action) => {
      state.formulaire = action.payload;
    });
    
    builder.addMatcher(
      signatureApi.endpoints.getEmployeSignature.matchFulfilled,
      (state, action) => {
        state.employeInfo = action.payload;
        state.idUnique = action.meta.arg.originalArgs.idUnique;

        // set tout les consentement absent explicitement à false
        const missingConsentements = state.employeInfo.config?.objetsDeConsentement.filter(
          x => state.employeInfo?.consentements.findIndex(c => c.objetDeConsentementId === x.id) === -1);

        if (missingConsentements && state.employeInfo.consentements) {
          state.employeInfo.consentements =
            [...state.employeInfo.consentements,
              ...missingConsentements.map(x => ({ objetDeConsentementId: x.id, consent: false }))];

        }
      },
    );
  },
});

export const {
  goToPreviousEtape,
  goToNextEtape,
  updateInfosPerso,
  setSignatureCarte,
  setSignatureDemission,
  removeSignatureDemission,
  setAdhesion,
  setConsentement,
} = signatureSlice.actions;
export const accountSliceName = signatureSlice.name;

export const selectCurrentEtapeIndex = (state: RootState) => state.signature.currentEtapeIndex;
export const selectEmployeInfo = (state: RootState) => state.signature.employeInfo;
export const selectSignatureCarte = (state: RootState) => state.signature.signatureCarte;
export const selectSignatureDemission = (state: RootState) => state.signature.signatureDemission;
export const selectAdhesion = (state: RootState) => state.signature.adhesion;
export const selectIdUnique = (state: RootState) => state.signature.idUnique;
export const selectInfoMaj = (state: RootState) => state.signature.infoMAJ;
export const selectFormulaire = (state: RootState) => state.signature.formulaire;

export default signatureSlice.reducer;

export {};

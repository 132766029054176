// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import signatureApi from '../services/signatureApi';
// eslint-disable-next-line import/no-cycle
import { RootState } from './store';
// eslint-disable-next-line import/no-cycle
import formulaireApi from '../services/formulaireApi';

const sliceName = 'theme';

export interface ThemeSlice {
  primaryColor: string;
  secondaryColor: string;
}

const initialState: ThemeSlice = { primaryColor: '#164276', secondaryColor: '#3681c3' };

export const themeSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<{ primaryColor: string; secondaryColor: string; }>) => {
      state.primaryColor = action.payload.primaryColor;
      state.secondaryColor = action.payload.secondaryColor;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      signatureApi.endpoints.getEmployeSignature.matchFulfilled,
      (state, action) => {
        if ('config' in action.payload) {
          state.primaryColor = action.payload.config!.primaryColor;
          state.secondaryColor = action.payload.config!.secondaryColor;
        }
      });
    builder.addMatcher(
      signatureApi.endpoints.getConfigNouvelleAdhesion.matchFulfilled,
      (state, action) => {
        state.primaryColor = action.payload.primaryColor;
        state.secondaryColor = action.payload.secondaryColor;
      });
    builder.addMatcher(
      formulaireApi.endpoints.getFormulaireByRoute.matchFulfilled,
      (state, action) => {
        state.primaryColor = action.payload.primaryColor;
        state.secondaryColor = action.payload.secondaryColor;
      });
  },
});

// export const {} = themeSlice.actions;

export const accountSliceName = themeSlice.name;

export const selectTheme = (state: RootState) => state.theme;

export default themeSlice.reducer;

export {};

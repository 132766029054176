/* eslint-disable import/no-cycle */

import baseApi from './baseApi';
import {
  FormulaireAddCommand, FormulaireDuplicateCommand,
  FormulairePourSignatureGetData,
  FormulaireRouteData,
  FormulairesGetData,
  FormulaireUpdateCommand,
  SubmitFormulaireCommand,
} from './Schemas/Formulaires';

const formulaireApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllFormulaires: builder.query<FormulairesGetData[], void>({
      query: () => `formulaire`,
      providesTags: (result, error) => {
        const tags = [{ type: 'Formulaire', id: 'LIST' }] as { type: 'Formulaire', id: string }[];
        if (!error && result) {
          result.forEach((formulaire) => {
            tags.push({ type: 'Formulaire', id: formulaire.id.toString() });
          });
        }
        return tags;
      },
    }),
    getAllFormulairesRoutes: builder.query<FormulaireRouteData[], void>({
      query: () => `formulaire/routes`,
    }),
    getFormulaireById: builder.query<FormulairesGetData, number>({
      query: (id) => `formulaire/${id}`,
      providesTags: (_result, _error, args) => [{ type: 'Formulaire', id: args }],
    }),
    addFormulaire: builder.mutation<void, FormulaireAddCommand>({
      query: (body) => ({
        url: `formulaire`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Formulaire', id: 'LIST' }],
    }),
    getFormulaireByRoute: builder.query<FormulairePourSignatureGetData, {
      routeClient: string,
      routeFormulaire: string
    }>({
      query: (body) => `formulaire/par-route/${body.routeClient}/${body.routeFormulaire}`,
    }),
    updateFormulaire: builder.mutation<void, FormulaireUpdateCommand>({
      query: (body) => {

        const formData = new FormData();
        formData.append('Nom', body.nom);
        formData.append('Route', body.route);
        if (body.description) {
          formData.append('Description', body.description);
        }
        if (body.titre) {
          formData.append('Titre', body.titre);
        }
        formData.append('Id', body.id.toString());
        formData.append('PrimaryColor', body.primaryColor);
        formData.append('SecondaryColor', body.secondaryColor);
        if (body.introduction) {
          formData.append('Introduction', body.introduction);
        }
        formData.append('Remerciement', body.remerciement);
        formData.append('SectionInfoPersonnelles', body.sectionInfoPersonnelles.toString());
        formData.append('SectionAdhesion', body.sectionAdhesion.toString());
        formData.append('SectionDemission', body.sectionDemission.toString());
        formData.append('SectionConsentements', body.sectionConsentements.toString());
        formData.append('AfficherLaCarteDeMembreALaFin', body.afficherLaCarteDeMembreALaFin.toString());

        if (body.banniere) {
          formData.append('Banniere', body.banniere);
        }


        return ({
          url: `formulaire`,
          method: 'PUT',
          body: formData,
        });
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'Formulaire', id: 'LIST' }, {
        type: 'Formulaire',
        id: args.id.toString(),
      }],
    }),

    duplicateFormulaire: builder.mutation<void, FormulaireDuplicateCommand>({
      query: (body) => ({
        url: `formulaire/duplicate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Formulaire', id: 'LIST' }],
     
    }),

    deleteFormulaire: builder.mutation<void, number>({
      query: (id) => ({
        url: `formulaire/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'Formulaire', id: 'LIST' }, {
        type: 'Formulaire',
        id: args.toString(),
      }],

    }),

    soumettreFormulaire: builder.mutation<void, SubmitFormulaireCommand>({
      query: ({ ...body }) => ({
        url: 'formulaire/soumettre',
        method: 'POST',
        body,
      }),
    }),


  }),

});

export const {
  useGetAllFormulairesQuery,
  useGetFormulaireByIdQuery,
  useAddFormulaireMutation,
  useUpdateFormulaireMutation,
  useGetFormulaireByRouteQuery,
  useSoumettreFormulaireMutation,
  useGetAllFormulairesRoutesQuery,
  useDeleteFormulaireMutation,
  useDuplicateFormulaireMutation,
} = formulaireApi;


export default formulaireApi;
